import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { MixpanelService } from "@ds-common-services/utility-services/mixpanel.service";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { GlobalService } from "@ds-common-services/http-services/global.service";
import { customerList } from "./Config";
import {
	IErrorResponse,
	IMLserviceResponse,
	Ilanguage,
	IloginResponse,
	LoginCred
} from "@ds-shared/models/login.model";
import { HttpErrorResponse } from "@angular/common/http";
import { IUserInfo } from "@ds-shared/models/common.model";
import { AccessMatrixModel } from "@ds-shared/models/access-matrix.model";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
	public loginForm: UntypedFormGroup;
	public submitted: boolean = false;
	public showPassword: boolean = true;
	public language: Ilanguage;
	public isLoading: boolean = false;
	private destroy$: Subject<boolean> = new Subject();
	constructor(
		private localStorageService: LocalStorageService,
		private notificationService: NotificationService,
		private dataTransferService: DataTransferService,
		private accessMatrixService: AccessMatrixService,
		private modalService: NgbModal,
		private commonFunctions: CommonFunctionsService,
		private globalService: GlobalService,
		private mixpanelService: MixpanelService,
		public router: Router
	) {}

	ngOnInit(): void {
		this.modalService.dismissAll();
		this.language = this.commonFunctions.getLang();
		this.localStorageService.clearAll();
		this.loginForm = new UntypedFormGroup({
			// eslint-disable-next-line @typescript-eslint/unbound-method
			email_id: new UntypedFormControl(null, Validators.required),
			// eslint-disable-next-line @typescript-eslint/unbound-method
			password: new UntypedFormControl(null, Validators.required)
		});
		this.commonFunctions.setLang(this.language);
	}

	loginPromise(loginCred: LoginCred) {
		return new Promise((resolve, reject) => {
			this.isLoading = true;
			this.globalService
				.checkAuthorization(loginCred)
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					(res: IloginResponse) => {
						if (res) {
							this.localStorageService.set("jwt", res["data"].access);
							resolve(res);
						}
					},
					(err: HttpErrorResponse) => {
						const { summary, data, context_code } = err.error as IErrorResponse;
						const errorActions: Record<string, () => void> = {
							"Password is incorrect": () =>
								this.setLoginError(
									"password",
									"passwordError",
									"Password is incorrect"
								),
							"No active account found with the given credentials": () =>
								this.setLoginError(
									"email_id",
									"emailError",
									"No active account found with the given credentials"
								),
							"User is disabled": () =>
								this.setLoginError(
									"email_id",
									"emailError",
									"User is disabled"
								),
							"Email or password is incorrect": () =>
								this.setNotificationMessage(context_code, summary),
							"Request limit exceeded.": () =>
								this.setRequestLimitExceededMessage(data)
						};
						if (errorActions[summary]) {
							errorActions[summary]();
						} else {
							console.warn("Unhandled error case:", summary);
						}

						reject();
					}
				);
		});
	}

	private setLoginError(ctrl: string, fieldTag: string, msg: string): void {
		this.loginForm.controls[ctrl].setErrors({
			[fieldTag]: msg
		});
	}

	private setNotificationMessage(contextCode: number, summary: string): void {
		this.notificationService.setMessage(contextCode, summary);
	}

	private setRequestLimitExceededMessage(data: {
		try_after: string;
		units: string;
	}): void {
		const tryAfter = parseFloat(data?.try_after);
		if (isNaN(tryAfter)) return;
		this.setNotificationMessage(
			1100,
			`Request limit exceeded. Please try again after ${Math.ceil(
				tryAfter / 60
			)} minutes.`
		);
	}

	onSubmit() {
		this.submitted = true;
		if (this.loginForm.status == "VALID") {
			this.authorize(
				this.loginForm.get("email_id").value as string,
				this.loginForm.get("password").value as string
			);
		}
	}
	showLoginPassword() {
		this.showPassword = !this.showPassword;
	}

	authorize(emailId: string, password: string) {
		this.isLoading = true;
		const loginCred: LoginCred = {
			email: emailId,
			password: password
		};
		this.loginPromise(loginCred)
			.then((loginResponse: IloginResponse) => {
				this.globalService
					.getUserInfo()
					.pipe(takeUntil(this.destroy$))
					.subscribe((userInfo: IUserInfo) => {
						this.getMlServicesList();
						this.commonFunctions.setLang(this.language);
						this.localStorageService.set(
							this.localStorageService.currencyListStorageKey,
							this.commonFunctions.getEncodedData(
								JSON.stringify(loginResponse["data"].currency)
							)
						);
						this.commonFunctions.currencySelected =
							loginResponse["data"].currency;
						this.getUserRoleInfo(loginResponse, userInfo);
						this.setUserData(userInfo);
						this.isLoading = false;
					});
			})
			.catch(() => {
				this.isLoading = false;
			});
	}

	private setUserData(response: IUserInfo) {
		this.mixpanelService.init(response.data.id, response.data);
		//Hide submenus for a specific Customer
		customerList[
			response.data.customer.attributes.name.toLocaleLowerCase()
		]?.forEach(
			(subMenus: string) => (this.commonFunctions.hideSubmenus[subMenus] = true)
		);
		response.data = {
			...response.data,
			hideSubmenus: this.commonFunctions.hideSubmenus
		};
		response = { ...response, data: response.data };
		this.mixpanelService.track("Login action", {});
		this.localStorageService.set(
			"user",
			this.commonFunctions.getEncodedData(JSON.stringify(response))
		);
		this.dataTransferService.sendSignalAfterSuccessLogin(true);
	}

	private getUserRoleInfo(loginDetails: IloginResponse, userInfo: IUserInfo) {
		userInfo.data.role.modules.forEach((item: AccessMatrixModel) => {
			item["path"] = AccessMatrix[item.module_name];
		});
		this.accessMatrixService.userRoles = userInfo.data.role.modules;
		this.accessMatrixService.navigateAfterLogin();
		this.dataTransferService.sendPreloadingStrategyData(true);
		this.notificationService.setMessage(
			loginDetails["context_code"],
			this.commonFunctions.getSpecificTranslation(
				"Welcome to Anchanto Digital Shelf"
			) + `, ${loginDetails.data.username}!`
		);
	}

	getMlServicesList() {
		this.globalService
			.getMLServiceList()
			.pipe(
				map((res: IMLserviceResponse) => {
					const codeToSortId: { [key: string]: number } = {
						pm: 1,
						cq: 2,
						sa: 3
					};
					res["data"] =
						res["data"]
							.map((item) => {
								item["sortId"] = codeToSortId[item.code];
								return item;
							})
							.sort((a, b) => a.sortId - b.sortId) || [];
					return res;
				})
			)
			.subscribe(
				(res) => {
					if (res) {
						this.localStorageService.set(
							this.localStorageService.mlServicesStorageKey,
							this.commonFunctions.getEncodedData(JSON.stringify(res["data"]))
						);
					}
				},
				() => {
					this.localStorageService.set(
						this.localStorageService.mlServicesStorageKey,
						this.commonFunctions.getEncodedData(JSON.stringify([]))
					);
				}
			);
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
