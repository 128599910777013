export const TagsList = [
  {
      id:1,
      attributes:{
        name:'Dropped Price',
        value: "dropped_price"
      }
  }, 
  {
      id:2,
      attributes:{
        name:'Overpriced',
        value: "overpriced"
      }
  }, 
  {
      id:3,
      attributes:{
        name:'Underpriced',
        value: "underpriced"
      }
  }, 
  {
      id:4,
      attributes:{
        name:'Negative Sentiment',
        value: "neg_sentiment"
      }
  }, 
  {
      id:5,
      attributes:{
        name:'Poor Ratings',
        value: "poor_ratings"
      }
  }, 
  {
      id:6,
      attributes:{
        name:'Low Content Quality',
        value: "low_content"
      }
  }, 
  {
      id:7,
      attributes:{
        name:'High Engagement',
        value: "high_engagement"
      }
  }, 
  {
      id:8,
      attributes:{
        name:'Increased Discount',
        value: "more_discount"
      }
  }, 
  {
      id:9,
      attributes:{
        name:'Increased Ratings',
        value: "increased_ratings"
      }
  }, 
  {
      id:10,
      attributes:{
        name:'New Vouchers Introduced',
        value: "new_vouchers"
      }
  },
  {
      id:11,
      attributes:{
        name:'New launches',
        value: "new_launches"
      }
  }
];

export const RatingsList = [
  {
      id:1,
      attributes:{
        name:'Less than 1',
        value:'0-0.99'
      }
  }, 
  {
      id:2,
      attributes:{
        name:'1-2',
        value:'1-2'
      }
  }, 
  {
      id:3,
      attributes:{
          name:'2-3',
          value: '2-3'
      }
  }, 
  {
      id:4,
      attributes:{
          name:'3-4',
          value:'3-4'
      }
  }, 
  {
      id:5,
      attributes:{
          name:'4-5',
          value:'4-5'
      }
  }, 
  {
      id:6,
      attributes:{
          name:'5',
          value:'5'
      }
  }, 
]