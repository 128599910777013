<div ngbDropdown class="d-inline-block position-static" display="dynamic" *ngIf="col?.method==='actions'">
    <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span class="roles-option"><i
                class="icon icon-Options"></i></span>
    </span>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="grid-action-dropdown">
        <button class="dropdown-item" (click)="openModal(deleteContent,'deleteView')">
            <i class="icon icon-Trash"></i>
            <span translate>Delete Action</span>
        </button>
    </div>
</div>

<ng-template #deleteContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" translate>Delete Action</h4>
    </div>
    <div class="modal-body">
        <i class="icon icon-Trash" ngbAutofocus></i>
        <div class="body-content">
            <p translate>Are you sure you want to delete this action?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')" translate> Cancel
        </button>
        <button type="button" class="btn btn-primary btn-lg"
            (click)="[deleteBulkActivity(actionData.id), modal.close('Close click')]" translate>Yes, Delete </button>
    </div>
</ng-template>