import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { ModuleType } from "@ds-shared/models/common.model";
import { UpdateSegmentActionComponent } from "@ds-shared/common-components/actions/update-segment-action/update-segment-action.component";
import { ScorecardService } from "@ds-private-layouts/scorecard/scorecard.service";
import { SCORECARD_TYPE } from "../../../enums/common.enum";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";

@Component({
	selector: "app-select-action-header",
	templateUrl: "./select-action-header.component.html",
	styleUrls: ["./select-action-header.component.scss"]
})
export class SelectActionHeaderComponent implements OnInit, OnDestroy {
	@Input() public data;
	@Input() public items;
	@Input() public col;
	@Input() public gridInfo: any;
	@Input() public bulkActionParams: any = {};
	@Output() bulkSelect = new EventEmitter();
	@Output() refreshGrid = new EventEmitter();
	private $destroy: Subject<boolean> = new Subject();
	public bulkSelected: boolean = false;
	public bulkExclude = {
		store: {
			key: "filter[stores]",
			list: [],
			selected: [],
			title: "Exclude by Store",
			exclude_key: "exclude_stores"
		},
		brand: {
			key: "filter[brands]",
			list: [],
			selected: [],
			title: "Exclude by Brand",
			exclude_key: "exclude_brands"
		},
		category: {
			key: "filter[categories]",
			list: [],
			selected: [],
			title: "Exclude by Category",
			exclude_key: "exclude_categories"
		},
		keyword: {
			key: "filter[search]",
			list: [],
			selected: [],
			title: "Exclude by Search Term",
			exclude_key: "exclude_keywords"
		}
	};
	public bulkExcludeInfo = {
		isBulkExclude: false,
		selectedExclude: []
	};
	constructor(
		private modalService: NgbModal,
		public router: Router,
		public scorecardService: ScorecardService,
		private route: ActivatedRoute,
		private notificationService: NotificationService,
		public dataTransferService: DataTransferService,
		public commonFunctions: CommonFunctionsService
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes?.data?.currentValue) {
			this.bulkSelected = this.items?.meta?.total_count === this.data?.size;
		}
	}
	ngOnInit(): void {}
	openModal(content, view) {
		let modalClass = "customModal";
		if (view === "bulkDiscard") {
			this.bulkExcludeInfo.isBulkExclude = false;
			this.bulkExcludeInfo.selectedExclude = [];
			const tempParams = this.commonFunctions.filterPayload(
				this.bulkActionParams
			);
			Object.keys(this.bulkExclude).forEach((item) => {
				this.bulkExclude[item].list = [];
				this.bulkExclude[item].selected = [];
				if (this.bulkExclude[item].key in tempParams) {
					this.bulkExcludeInfo.isBulkExclude = true;
					this.bulkExcludeInfo.selectedExclude.push(
						item === "keyword"
							? "Search Term"
							: this.commonFunctions.capitalizeFirstLetter(item)
					);
					this.bulkExclude[item].list =
						this.bulkExclude[item].key === "filter[search]"
							? [decodeURIComponent(tempParams[this.bulkExclude[item].key])]
							: tempParams[this.bulkExclude[item].key].map((name) =>
									decodeURIComponent(name)
							  );
				}
			});
			if (this.bulkExcludeInfo.selectedExclude.length)
				modalClass = `customModal bulkDiscard${this.bulkExcludeInfo.selectedExclude.length}`;
		}
		this.modalService.open(content, {
			centered: true,
			windowClass: modalClass
		});
	}

	openStoreSegmentSelectionModal() {
		const modalRef = this.modalService.open(UpdateSegmentActionComponent, {
			centered: true,
			windowClass: "customModal",
			keyboard: false,
			backdrop: "static"
		});
		modalRef.componentInstance.data = this.data;
		modalRef.result.then(
			(result) => {
				if (!result) return;
				this.bulkSelect.emit(false);
				this.refreshGrid.emit(true);
			},
			(reason) => {}
		);
	}

	selectAllFunc() {
		this.bulkSelected = !this.bulkSelected;
		this.bulkSelect.emit(this.bulkSelected);
	}
	deleteProfile() {
		const profileType = this.route.snapshot["_urlSegment"]?.segments[1]?.path;
		let payload = {
			action_on_all: this.data.size === 0,
			profile_type: profileType
		};
		if (this.data.size) payload["profiles"] = Array.from(this.data);
		let queryParam = {};
		if (this.bulkSelected) {
			queryParam = {
				...this.commonFunctions.filterPayload(this.bulkActionParams)
			};
		}
		this.scorecardService
			.bulkDelete("scorecard/profiles-delete", queryParam, payload)
			.pipe(takeUntil(this.$destroy))
			.subscribe(
				(res: any) => {
					if (res) {
						this.dataTransferService.sendRefreshProfileGridRequest(true);
						this.notificationService.setMessage(
							res["context_code"],
							res["summary"]
						);
						this.bulkSelect.emit(false);
						this.dataTransferService.sendfireMenuCount("scorecard");
					}
				},
				(err: any) => {
					this.notificationService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
				}
			);
	}
	deleteFunc() {
		if (
			[
				"store-tracker",
				"product-tracker",
				"brand-tracker",
				"keyword-tracker",
				"category-tracker"
			].includes(this.gridInfo?.type)
		)
			this.deleteProfile();
	}

	public deactivateProfile(): void {
		let data = {
			action_on_all: this.data.size === 0,
			is_active: false,
			profile_type: SCORECARD_TYPE[this.router.url.split("/").pop()]
		};
		if (this.data.size) data["profiles"] = Array.from(this.data);
		let queryParam = {};
		if (this.bulkSelected) {
			queryParam = {
				...this.commonFunctions.filterPayload(this.bulkActionParams)
			};
		}
		this.scorecardService
			.changeProfileStatus("scorecard/start-stop-scraping", queryParam, data)
			.pipe(takeUntil(this.$destroy))
			.subscribe(
				(res: any) => {
					if (res) {
						this.notificationService.setMessage(
							res["context_code"],
							res["summary"]
						);
						this.modalService.dismissAll();
						this.refreshGrid.emit(true);
					}
				},
				(err) => {
					this.notificationService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
				}
			);
	}

	public discardProducts(): void {
		let data = {
			action_on_all: this.data.size === 0,
			is_discarded: true,
			discard_source: "bulk_discard"
		};
		if (this.data.size) data["product_ids"] = Array.from(this.data);
		let queryParam = {};
		if (this.bulkSelected) {
			queryParam = {
				...this.commonFunctions.filterPayload(this.bulkActionParams)
			};
		}
		Object.keys(this.bulkExclude).forEach((item) => {
			if (this.bulkExclude[item].selected.length)
				data[this.bulkExclude[item].exclude_key] =
					this.bulkExclude[item].selected;
		});
		this.scorecardService
			.bulkDiscardProducts(
				"scorecard/active-to-and-fro-discard-products",
				queryParam,
				data
			)
			.pipe(takeUntil(this.$destroy))
			.subscribe(
				(res: any) => {
					if (res) {
						this.notificationService.setMessage(
							res["context_code"],
							res["summary"]
						);
						this.modalService.dismissAll();
						this.refreshGrid.emit(true);
					}
				},
				(err) => {
					this.notificationService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
				}
			);
	}
	onExcludeChange(event: any, all_items: boolean, type, item) {
		if (all_items) {
			this.bulkExclude[type].selected = event.target.checked
				? this.bulkExclude[type].list
				: [];
		} else {
			if (event.target.checked) this.bulkExclude[type].selected.push(item);
			else {
				this.bulkExclude[type].selected = this.bulkExclude[
					type
				].selected.filter((data) => data !== item);
			}
		}
	}

	public ngOnDestroy(): void {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
