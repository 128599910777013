<div class="advanced-filter cursor-pointer d-flex align-items-center justify-content-center position-relative" [ngStyle]="optionalFilterParams?.style" (click)="openFilter()" [ngClass]="{'isDisabled': isDisabled}">
    <i class="icon icon-Filter" placement="bottom" ngbTooltip="{{'Filter' | translate}}"
    tooltipClass="info-custom-tooltip"></i>
    <div class="filter-active" *ngIf="isOptionalActive"></div>
</div>
<ng-container *ngIf="isOpen">
    <div class="overlay"></div>
    <div class="right-panel">
        <div class="header-container d-flex align-items-center justify-content-between  text-align-right-rtl">
            <span class="d-flex" translate>Filter Data</span>
            <i class="icon icon-Close2 cursor-pointer" (click)="isOpen=!isOpen" placement="left"
                ngbTooltip="{{'Close' | translate}}" tooltipClass="info-custom-tooltip"></i>
        </div>
        <div class="d-flex align-items-center gap-6 filter-info mt-3">
            <i class="icon icon-Info-circle"></i>
            <span translate>Optional Filters</span>
        </div>
        <form [formGroup]="filterForm" class="filterForm text-align-right-rtl mt-3" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
            <ng-container *ngFor="let filter of filters">
                <div class="select-container" [ngClass]="{'mb-0': filter === 'price_range'}" *ngIf="optionalFilterParams?.filterRequired[filter]"> <label translate>{{filterList[filter].placeholder}}</label>
                    <ng-container *ngIf="filterList[filter].type === 'select'">
                        <ng-container *ngIf="!filterList[filter].multiple; else multiple">
                            <ng-container *ngIf="filter === 'country'">
                                <ng-select class="select-text-wrap" [notFoundText]="'No Data Found' | translate"
                                [loadingText]="'Loading...' | translate" [items]="filterList[filter].list"
                                placeholder="{{('Select ' + filterList[filter].placeholder) | translate}}" bindLabel="attributes.name"
                                formControlName="{{filter}}" [loading]="filterList[filter].loading" [closeOnSelect]="filterList[filter].closeOnSelect" [clearable]="filterList[filter].clear" [multiple]="filterList[filter].multiple" (change)="onChange(filter)" (clear)="closeDropdown(filter)">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="currency-container"> {{item?.attributes?.symbol}} | </span>
                                    {{item?.attributes?.country}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span class="d-inline-block float-start curency-name">{{item?.attributes?.country}}</span>
                                    <span class="float-end currency-symbol">{{item?.attributes?.name}}({{item?.attributes?.symbol}})</span>
                                </ng-template>
                             </ng-select>
                            </ng-container>
                            <ng-container *ngIf="filter !== 'country'">
                                <ng-select class="select-text-wrap" [notFoundText]="'No Data Found' | translate"
                                [loadingText]="'Loading...' | translate" [items]="filterList[filter].list"
                                placeholder="{{('Select ' + filterList[filter].placeholder) | translate}}" bindLabel="attributes.name"
                                formControlName="{{filter}}" [loading]="filterList[filter].loading" [closeOnSelect]="filterList[filter].closeOnSelect" [clearable]="filterList[filter].clear" [multiple]="filterList[filter].multiple" (change)="onChange(filter)" (clear)="closeDropdown(filter)"> </ng-select>
                            </ng-container>
                        </ng-container>
                        <ng-template #multiple>
                            <ng-select [items]="filterList[filter].list" [notFoundText]="'No Data Found' | translate"
                                [loadingText]="'Loading...' | translate" [multiple]="true"
                                (close)="closeMultiDropdown(filter)" [closeOnSelect]="filterList[filter].closeOnSelect" [clearable]="filterList[filter].clear" bindLabel="attributes.name"
                                placeholder="{{('Select ' + filterList[filter].placeholder) | translate}}" [loading]="filterList[filter].loading" formControlName="{{filter}}"
                            (search)="onMultiSearch($event,filter)" (change)="onFilterSelect($event, filter)">
                                <ng-template ng-multi-label-tmp><span class="placeholder-temp">{{filterList[filter].searchTerm?.length ? ''
                                        :
                                        ((filterList[filter].placeholder | translate) + " (" +
                                        filterForm.get(filter).value?.length + ")")}}</span></ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <div class="multi-select-option-container multi-text-wrap gap-16">
                                        <!-- <app-marketplace-store-logo [storeName]="item.attributes.marketplace"></app-marketplace-store-logo> -->
                                        <span class="item-name">{{item.attributes.name}}</span>
                                        <i class="icon icon-Check" *ngIf="addTick(item,filter)"></i>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </ng-template>
                    </ng-container>
                    </div>
                <ng-container *ngIf="filterList[filter].type === 'range' && (filterList[filter].list) && optionalFilterParams?.filterRequired[filter]">
                        <div class="rangeContainer filRange" [ngClass]="{'position-relative': options.disabled}" ngbTooltip={{getTooltipForPriceRange()}}>
                            <ngx-slider [(value)]="filterList[filter].list.min_price"
                                [(highValue)]="filterList[filter].list.max_price"
                                [options]="options" (userChangeEnd)="onPriceChange('range', $event)" formControlName="{{filter}}"></ngx-slider>
                            <div class="rangeDisplay mb-4">
                                <div class="rangeContainer d-flex gap-10" [ngClass]="{'position-relative': options.disabled}">
                                    <div class="valContainer" [ngClass]="{'cursor-not-allowed':!filterList[filter].list.min_price}">
                                        {{commonFunctions.getSpecificTranslation('MIN') +
                                        (rangeFiltersSelected.selectedOption.units)}}
                                        <input type="number" [attr.data-test]="'min-range-test'"
                                            [value]="filterList[filter].list.min_price"
                                            [min]="filterList[filter].list.min_price"
                                            [max]="filterList[filter].list.max_price"
                                            (input)="onPriceChange('min',$event)"
                                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                            [disabled]="options.disabled">
                                        <div *ngIf="!filterList[filter].list.min_price">--</div>
                                    </div>
                                    <div class="valContainer" [ngClass]="{'cursor-not-allowed':!filterList[filter].list.max_price}">
                                        {{commonFunctions.getSpecificTranslation('MAX') +
                                        (rangeFiltersSelected.selectedOption.units)}}
                                        <input type="number" [attr.data-test]="'max-range-test'"
                                            [value]="filterList[filter].list.max_price"
                                            [min]="filterList[filter].list.min_price"
                                            [max]="filterList[filter].list.max_price"
                                            (input)="onPriceChange('max',$event)"
                                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                            [disabled]="options.disabled"
                                            >
                                        <div *ngIf="!filterList[filter].list.max_price">--</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            <div class="filter-footer d-flex align-items-center justify-content-between">
                <div class="clear-filter d-flex align-items-center gap-8 cursor-pointer" (click)="clearFilter()">
                    <i class="icon icon-Refresh"></i>
                    <span translate>Clear All Filters</span>
                </div>
                <div class="d-flex align-items-center gap-16">
                    <button class="btn btn-secondary btn-lg" type="button" (click)="isOpen=!isOpen" translate>Cancel</button>
                    <button class="btn btn-primary btn-lg" type="submit" translate>Apply Filter</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>