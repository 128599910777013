export const customerList: { [key: string]: string[] } = {
	levents: [
		"/sales/dealer-analysis",
		"/sales/brand-analysis",
		"/sales/sku-analysis",
		"/sales/oms",
		"/pricing/promotion-analysis"
	],
	panasonic: [
		"/sales/dealer-analysis",
		"/sales/brand-analysis",
		"/sales/sku-analysis"
	],
	"optimum nutrition": [
		"/pricing/promotion-analysis",
		"/sales/dealer-analysis",
		"/sales/brand-analysis",
		"/sales/sku-analysis"
	],
	transcosmos: [
		"/sales/dealer-analysis",
		"/sales/brand-analysis",
		"/sales/sku-analysis",
		"/sales/oms"
	]
};
