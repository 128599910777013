import { Injectable } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Injectable({
	providedIn: "root"
})
export class CustomValidationsService {
	constructor() {}

	validateOnlyAlphaNumeric(c: UntypedFormControl): { [s: string]: string } {
		let val = c.value;
		if (!new RegExp("^[a-zA-Z0-9 ]*$").test(val)) {
			return { fieldMessage: "Only alphabets and numerics are allowed" };
		} else return null;
	}

	validatePasswordPattern(c: UntypedFormControl) {
		let val = c.value;
		if (
			val &&
			!new RegExp(
				"^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#!%$^&*()~`]).{8,}$"
			).test(val)
		) {
			return {
				fieldMessage:
					"Password must contain 8 characters, including one special character, one number and one capital letter"
			};
		} else return null;
	}

	validateInputString(c: UntypedFormControl | {value: string}) {
		let val = c.value;
		if (val && !new RegExp("^[\x00-\x7F]*$").test(val)) {
			return {
				fieldMessage: "Special characters are not allowed."
			};
		} 
		if(val && !new RegExp("^(?=.*[a-zA-Z]).{3,15}$").test(val)) {
			return {
				fieldMessage: "Name must be between 3 to 15 characters & contains at least one alphabets."
			}
		}
		else return null;
	}
}
