import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./layouts/public-layouts/login/login.component";
import { PreloadingStrategyService } from "@ds-common-services/utility-services/preloading-strategy.service";
import { AuthGuard } from "@ds-guards/auth.guard";
import { LoggedInGuard } from "@ds-guards/logged-in.guard";
import { ResetPasswordComponent } from "./layouts/public-layouts/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "@ds-public-layouts/forgot-password/forgot-password.component";

const routes: Routes = [
	{
		path: "",
		component: LoginComponent,
		pathMatch: "full",
		canActivate: [LoggedInGuard]
	},
	{ path: "reset-password", component: ResetPasswordComponent },
	{ path: "forgot-password", component: ForgotPasswordComponent },
	{ path: 'verify-user', loadComponent: () => import('./layouts/public-layouts/verify-user/verify-user.component').then(m => m.VerifyUserComponent)},
	{
		path: "sample",
		loadChildren: () =>
			import("./sample/sample.module").then((m) => m.SampleModule)
	},
	{
		path: "dashboard",
		canActivate: [AuthGuard],
		data: { preload: true, delay: 100 },
		loadChildren: () =>
			import("./layouts/private-layouts/dashboard/dashboard.module").then(
				(m) => m.DashboardModule
			)
	},
	{
		path: "insights",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import("./layouts/private-layouts/profile-insight/insight.module").then(
				(m) => m.InsightModule
			)
	},
	{
		path: "discover",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import("./layouts/private-layouts/discover/discover.module").then(
				(m) => m.DiscoverModule
			)
	},
	{
		path: "configuration",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import(
				"./layouts/private-layouts/configuration/configuration.module"
			).then((m) => m.ConfigurationModule)
	},
	{
		path: "reporting",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import("./layouts/private-layouts/reporting/reporting.module").then(
				(m) => m.ReportingModule
			)
	},
	{
		path: "user-management",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import(
				"./layouts/private-layouts/user-management/user-management.module"
			).then((m) => m.UserManagementModule)
	},
	{
		path: "sales",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import("./layouts/private-layouts/sellout/sellout.module").then(
				(m) => m.SelloutModule
			)
	},
	{
		path: "scorecard",
		canActivate: [AuthGuard],
		data: { preload: true, delay: 100 },
		loadChildren: () =>
			import("./layouts/private-layouts/scorecard/scorecard.module").then(
				(m) => m.ScorecardModule
			)
	},
	{
		path: "availability",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import("./layouts/private-layouts/availability/availability.module").then(
				(m) => m.AvailabilityModule
			)
	},
	{
		path: "pricing",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import("./layouts/private-layouts/pricing/pricing.module").then(
				(m) => m.PricingModule
			)
	},
	{
		path: "integration",
		loadChildren: () =>
			import("./layouts/private-layouts/integration/integration.module").then(
				(m) => m.IntegrationModule
			)
	},
	{
		path: "notification",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import(
				"./layouts/private-layouts/notifications/notifications.module"
			).then((m) => m.NotificationsModule)
	},
	{
		path: "content-quality",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import(
				"./layouts/private-layouts/content-quality/content-quality.module"
			).then((m) => m.ContentQualityModule)
	},
	{
		path: "share-of-search",
		canActivate: [AuthGuard],
		data: { preload: false, delay: 100 },
		loadChildren: () =>
			import(
				"./layouts/private-layouts/share-of-search/share-of-search.module"
			).then((m) => m.ShareOfSearchModule)
	},
	{
		path: "**",
		loadChildren: () =>
			import(
				"./layouts/public-layouts/page-not-found/page-not-found.module"
			).then((m) => m.PageNotFoundModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadingStrategyService
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
